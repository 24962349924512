<template lang="">
    <div>
        <CreatureEditor :docid="$route.params.docid" />
    </div>
</template>
<script>
import CreatureEditor from '@/components/CreatureEditor'
export default {
    components: {
        CreatureEditor
    }
    
}
</script>
<style lang="">
    
</style>