<template lang="">
  <div class="field has-text-left">
    <label class="label" for="{{name}}">{{prettyname}}: </label>
      <select 
        :id="name" 
        class="select"  
        :value="modelValue"
        @change="selectChanged">
          <option disabled value="">Please select one</option>
          <option v-for="option in options" v-bind:value="option.text" :key="option">
            {{ option.text }}
          </option>
      </select>
  </div>
</template>
<script>
export default {
  props: ['modelValue', 'name','prettyname','options'],
  emits: ['update:modelValue'],
  methods: {
    selectChanged(evt) {
      this.$emit('update:modelValue', evt.target.value);
    }
  },
  computed: {

  }
}
</script>
<style lang="">
  
</style>