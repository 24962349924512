<template lang="">
  <div class="field has-text-left">
    <label class="label">{{headername}}: </label>
    <div class="control is-horizontal">
      <span v-for="name in prettynames" :key="name" class="columns">
        <span class="column is-1"></span>
        <label class="column is-2 py-0">{{ name }}:</label>
          <input 
            class="input column is-narrow is-small"  
            :type="type"
            :name="name"
            :value="modelValue[name]"
            @input="inputChanged($event)" />
          
        </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['modelValue', 'headername','prettynames', 'type'],
  data() {
    return {
      values:{}
    }
  },
  emits: ['update:modelValue'],
  methods: {
    inputChanged(event) {

      if(event.target.value && event.target.value != "") {
        if(this.type == "number") {
          this.values[event.target.name] = parseInt(event.target.value);  
        }
        else {
          this.values[event.target.name] = event.target.value;
        }
      }
      this.$emit('update:modelValue',this.values)
    }
  },
  computed: {

  }
}
</script>
<style lang="">
  
</style>