<template lang="">
  <div v-if="loaded">
    <Inp v-model="name" prettyname="Monster Name"  />
    <DD v-model="race" name="race" prettyname="Race" :options="raceOptions" />
    <DD v-model="secrace" name="secrace" prettyname="Secondary Race" :options="secraceOptions" />
    <Inp v-model="eq" prettyname="EQ"  type="number" />
    <Inp v-model="level" prettyname="Level" type="number" />
    <Inp v-model="body" prettyname="Body" type="number" />
    <Inp v-model="agility" prettyname="Agility" type="number" />
    <Inp v-model="prowess" prettyname="Prowess" type="number" />
    <Inp v-model="spirit" prettyname="Spirit" type="number" />
    <Inp v-model="vision" prettyname="Vision" type="number" />
    <DD v-model="size" name="size" prettyname="Size" :options="sizeOptions" />

    <div class="field has-text-left">
      <label class="label">Vision Profile: </label>
      <div v-for="visionType in visionTypes" :key="visionType">
        <input type="checkbox"  :value="visionType" v-model="visionArray" />
        <label class="mx-2"> {{visionType}}</label>
      </div>
    </div>

    <DD v-model="hp" name="hp" prettyname="Hit Point Profile" :options="hpOptions" />
    <DD v-model="accuracy" name="accuracy" prettyname="Accuracy Profile" :options="accuracyOptions" />
    <Inp v-model="movement" prettyname="Movement Speed" type="number" />
    <TG v-model="altMoves" headername="Alternative Movement Types" :prettynames="altMoveTypes" type="number" />
    <DD v-model="resistance" name="resistance" prettyname="Resistance Profile" :options="resistanceOptions" />
    <DD v-model="natArmor" name="natArmor" prettyname="Natural Armor Profile" :options="natArmorOptions" />
    <TG v-model="specificArmors" headername="Specific Armour Types" :prettynames="specificArmorTypes" type="number" />
    <TG v-model="specialArmors" headername="Special Armour Types" :prettynames="specialArmorTypes" type="number" />
    <Inp v-model="immunities" prettyname="Immunities/Vivacity"  />
    <Inp v-model="vulnerabilities" prettyname="Vulnerabilities"  />

    <div class="field has-text-left">
      <label class="label">Skill Proficiencies: </label>
      <div v-for="skillType in skillTypes" :key="skillType">
        <input type="checkbox"  :value="skillType" v-model="skillArray" />
        <label class="mx-2"> {{skillType}}</label>
      </div>
    </div>

    <DD v-model="threat" name="threat" prettyname="Threat Profile" :options="threatOptions" />
    <Inp v-model="treasure" prettyname="Treasure"  />
    <Inp v-model="flavour" prettyname="Flavour"  />
    <Inp v-model="history" prettyname="History"  />
    <Inp v-model="tactics" prettyname="Tactics"  />
    <div class="p-field p-grid">
      <label class="p-col-2">Racial:</label>
      <Dropdown 
        v-model="racialAbility" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[2],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Base:</label>
      <Dropdown 
        v-model="baseAbility" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[1],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Tier 1:</label>
      <Dropdown 
        v-model="t1Ability" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[0],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Tier 2:</label>
      <Dropdown 
        v-model="t2Ability" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[0],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Tier 3:</label>
      <Dropdown 
        v-model="t3Ability" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[0],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Tier 4:</label>
      <Dropdown 
        v-model="t4Ability" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[0],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Boss:</label>
      <Dropdown 
        v-model="bossAbility" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[3],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>
    <div class="p-field p-grid">
      <label class="p-col-2">Hardcore:</label>
      <Dropdown 
        v-model="hardcoreAbility" 
        :filter="true"
        :showClear="true"
        :options="[abilityOptions[4],abilityOptions[5]]" 
        optionLabel="label" 
        optionValue="value"
        optionGroupLabel="label" 
        optionGroupChildren="items" />
    </div>

    <div v-if="isEdit">
      <button class="button" @click="updateCreatureNoLoop">Update Creature</button>
    </div>
    <div v-else>
      <button class="button" @click="addNewCreature">Add Creature</button>
    </div>
  </div>
</template>

<script>
import Inp from '@/components/htmlelements/SimpleTextInput'
import DD from '@/components/htmlelements/SimpleDropdown'
import TG from '@/components/htmlelements/TextInputGroup'
import Dropdown from 'primevue/dropdown'
import {mapGetters, mapActions} from "vuex"
import router from '@/router'

export default {
  data() {
    return {
        isLoaded: false,

        raceOptions: null,
        secraceOptions:null,
        sizeOptions: null,
        hpOptions: null,
        accuracyOptions: null,
        resistanceOptions: null,
        natArmorOptions: null,
        threatOptions: null,
        abilityOptions: null,

        visionTypes:null,
        skillTypes:null,
        altMoveTypes:null,
        specificArmorTypes:null,
        specialArmorTypes:null,


        name:null,
        race: null,
        secrace:null,
        eq:null,
        level:null,
        body:null,
        agility:null,
        prowess:null,
        spirit:null,
        vision:null,
        size:null,
        visionArray:[],
        hp:null,
        accuracy:null,
        movement:null,
        altMoves:{},
        resistance:null,
        natArmor:null,
        specificArmors:{},
        specialArmors:{},
        immunities:null,
        vulnerabilities:null,
        skillArray:[],
        threat:null,
        treasure:null,
        flavour:null,
        history:null,
        tactics:null,
        racialAbility:null,
        baseAbility:null,
        t1Ability:null,
        t2Ability:null,
        t3Ability:null,
        t4Ability:null,
        bossAbility:null,
        hardcoreAbility:null,


    }
  },
  props: [
    'docid',
    'creature',
  ],
  components: {
      Inp,
      DD,
      TG,
      Dropdown
  },
  computed: {
    ...mapGetters([
      'abilities',
      'lookups',
      'creatures',
      'user',
      'fbTimestamp',
      'getCreatureById',
    ]),
    isEdit() {
      return this.docid ? true : false;
    },
    loaded() {
      return this.abilities && this.creatures && this.lookups && this.isLoaded;
    },
  },
  methods: {
    ...mapActions([
      'fetchAbilities',
      'fetchLookups',
      'fetchCreatures',
      'addCreature',
      'updateCreature',
    ]),
    addNewCreature() {
      const result = this.generateCreatureObject();
      this.addCreature(result).then(() => {
        router.push('/creatures');
      });
    },
    updateCreatureNoLoop() {
      const creature = this.generateCreatureObject();
      this.updateCreature({creature:creature, docid:this.docid}).then(() => {
        router.push('/creatures');
      });
    },
    generateCreatureObject() {
      return {
        name:this.name,
        race:this.race,
        secrace:this.secrace,
        eq:this.floatOrNull(this.eq),
        level:this.intOrNull(this.level),
        body:this.intOrNull(this.body),
        agility:this.intOrNull(this.agility),
        prowess:this.intOrNull(this.prowess),
        spirit:this.intOrNull(this.spirit),
        vision:this.intOrNull(this.vision),
        size:this.size,
        visionArray:this.visionArray,
        hp:this.hp,
        accuracy:this.accuracy,
        movement:this.intOrNull(this.movement),
        altMoves: this.altMoves,
        resistance:this.resistance,
        natArmor:this.natArmor,
        specificArmors:this.specificArmors,
        specialArmors:this.specialArmors,
        immunities:this.immunities,
        vulnerabilities:this.vulnerabilities,
        skillArray:this.skillArray,
        threat:this.threat,
        treasure:this.treasure,
        flavour:this.flavour,
        history:this.history,
        tactics:this.tactics,
        racialAbility:this.racialAbility,
        baseAbility:this.baseAbility,
        t1Ability:this.t1Ability,
        t2Ability:this.t2Ability,
        t3Ability:this.t3Ability,
        t4Ability:this.t4Ability,
        bossAbility:this.bossAbility,
        hardcoreAbility:this.hardcoreAbility,
        createdAt: this.createdAt ? this.createdAt : this.fbTimestamp,
        lastModified: this.fbTimestamp,
        uid: this.uid ? this.uid : this.user.uid
      }
    }
  },
  created() {
    const loadPromises = [];
    if(!this.abilities) {
      loadPromises.push(this.fetchAbilities());
    }
    if(!this.lookups) {
      loadPromises.push(this.fetchLookups());
    }
    if(!this.creatures) {
      loadPromises.push(this.fetchCreatures());
    }
    Promise.all(loadPromises).then(() => {

      const raceTypeArray = this.lookups.raceType.types;
      this.raceOptions = [];
      this.secraceOptions = [{text:"None",value:"None"}];
      raceTypeArray.forEach(race => {
        this.raceOptions.push({text: race,value:race});
        this.secraceOptions.push({text: race,value:race});
      });
      const sizeTypeArray = this.lookups.sizeType.types;
      this.sizeOptions = [];
      sizeTypeArray.forEach(size => this.sizeOptions.push({text:size,type:size}) );

      const visionTypeArray = this.lookups.visionType.types;
      this.visionTypes = [];
      visionTypeArray.forEach(vision => this.visionTypes.push(vision) );

      const hpTypeArray = this.lookups.hpType.types;
      this.hpOptions = [];
      hpTypeArray.forEach(hp => this.hpOptions.push({text:hp,type:hp}) );
  
      const accuracyTypeArray = this.lookups.accuracyType.types;
      this.accuracyOptions = [];
      accuracyTypeArray.forEach(accuracy => this.accuracyOptions.push({text:accuracy,type:accuracy}) );


      const altMoveTypeArray = this.lookups.altMoveType.types;
      this.altMoveTypes = [];
      altMoveTypeArray.forEach(altMove => this.altMoveTypes.push(altMove) );

      const resistanceTypeArray = this.lookups.resistanceType.types;
      this.resistanceOptions = [];
      resistanceTypeArray.forEach(resistance => this.resistanceOptions.push({text:resistance,type:resistance}) );

      const natArmorTypeArray = this.lookups.natArmorType.types;
      this.natArmorOptions = [];
      natArmorTypeArray.forEach(natArmor => this.natArmorOptions.push({text:natArmor,type:natArmor}) );

      const specificArmorTypeArray = this.lookups.specificArmorType.types;
      this.specificArmorTypes = [];
      specificArmorTypeArray.forEach(specificArmor => this.specificArmorTypes.push(specificArmor) );

      const specialArmorTypeArray = this.lookups.specialArmorType.types;
      this.specialArmorTypes = [];
      specialArmorTypeArray.forEach(specialArmor => this.specialArmorTypes.push(specialArmor) );

      const skillTypeArray = this.lookups.skillType.types;
      this.skillTypes = [];
      skillTypeArray.forEach(skill => this.skillTypes.push(skill) );
    
      const threatTypeArray = this.lookups.threatType.types;
      this.threatOptions = [];
      threatTypeArray.forEach(threat => this.threatOptions.push({text:threat,type:threat}) );

      // Generate and sort abilitys options 
      this.abilityOptions = [
        {label:'Tier',items:[]},
        {label:'Base',items:[]},
        {label:'Racial',items:[]},
        {label:'Boss',items:[]},
        {label:'Hardcore',items:[]},
        {label:'Unclassified',items:[]},
      ];
      for(const abilDocId in this.abilities) {
        switch(this.abilities[abilDocId].classification) {
          case 'Tier':
            this.abilityOptions[0].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
          case 'Base':
            this.abilityOptions[1].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
          case 'Racial':
            this.abilityOptions[2].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
          case 'Boss':
            this.abilityOptions[3].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
          case 'Hardcore':
            this.abilityOptions[4].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
          default:
            this.abilityOptions[5].items.push({
              label: this.abilities[abilDocId].name,
              value: this.abilities[abilDocId].name,              
            });
            break;
        }
      }

      if(this.isEdit) {
        const doc = this.getCreatureById(this.docid);
        console.log(this.creatures, this.docid, this.creatures[this.docid]);

        this.name = doc.name;
        this.race = doc.race;
        this.secrace = doc.secrace;
        this.eq = doc.eq;
        this.level = doc.level;
        this.body = doc.body;
        this.agility = doc.agility;
        this.prowess = doc.prowess;
        this.spirit = doc.spirit;
        this.vision = doc.vision;
        this.size = doc.size;
        this.visionArray = doc.visionArray;
        this.hp = doc.hp;
        this.accuracy = doc.accuracy;
        this.movement = doc.movement;
        this.altMoves = doc.altMoves;
        this.resistance = doc.resistance;
        this.natArmor = doc.natArmor;
        this.specificArmors = doc.specificArmors;
        this.specialArmors = doc.specialArmors;
        this.immunities = doc.immunities;
        this.vulnerabilities = doc.vulnerabilities;
        this.skillArray = doc.skillArray;
        this.threat = doc.threat;
        this.treasure = doc.treasure;
        this.flavour = doc.flavour;
        this.history = doc.history;
        this.tactics = doc.tactics;
        this.racialAbility = doc.racialAbility;
        this.baseAbility = doc.baseAbility;
        this.t1Ability = doc.t1Ability;
        this.t2Ability = doc.t2Ability;
        this.t3Ability = doc.t3Ability;
        this.t4Ability = doc.t4Ability;
        this.bossAbility = doc.bossAbility;
        this.hardcoreAbility = doc.hardcoreAbility;
        this.createdAt = doc.createdAt;

      }
      this.isLoaded = true;
    });

  },
  emits: {

  }
};
</script>

<style lang="scss">
label {
  font-weight:700;
}
</style>