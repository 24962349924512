<template lang="">
  <div class="field has-text-left">
    <label class="label">{{prettyname}}: </label>
    <div class="control">
      <input 
        class="input"  
        :placeholder="placeholder"
        :value="modelValue"
        :type="type"
        :required="true"
        @input="$emit('update:modelValue',$event.target.value)">
    </div>
  </div>
</template>
<script>
export default {
  props: ['modelValue', 'prettyname','placeholder', 'type', 'required'],
  emits: ['update:modelValue'],
  computed: {
  }
}
</script>
<style lang="">
  
</style>